import { findIndex } from 'lodash';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { checkVideoUrlValidity } from '../../utils/utils';
import { CompleteVideoIcon, EditVideoIcon, PendingVideoIcon, ProcessingVideoIcon } from '../icons';
import clsx from 'clsx';
import { ProfileContext } from '../../contexts/profileContext';

import { getTranslation } from '../../utils';

export const VideoThumbnail = ({ videoId, videos, selectedVideoId, onSelect = () => {} }) => {
  const { t } = useTranslation();

  const [profile] = useContext(ProfileContext);

  const VIDEO_URL = process.env.REACT_APP_AWS_URL + 'profile-videos/';

  const [status, setStatus] = useState('pending'); // pending, processing, complete

  const {
    masterOrganization: { translationPrefix: orgPrefix }
  } = profile;

  const setVideoStatus = async () => {
    let index = findIndex(videos, ({ videoID: tVideoID }) => parseInt(tVideoID) === videoId);
    if (index >= 0) {
      const vidURL = videos[index].videoURL;
      if (vidURL === undefined) {
        setStatus('pending');
        return;
      }
      const videoURL = VIDEO_URL + vidURL;
      const isValid = await checkVideoUrlValidity(videoURL);
      if (!isValid) {
        setStatus('processing');
      } else {
        setStatus('complete');
      }
    }
  };

  useEffect(() => {
    setVideoStatus();
  }, [videoId, videos]);

  return (
    <button
      onClick={() => onSelect(videoId)}
      className={clsx(
        `block text-left border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl p-6`,
        { 'bg-gray-100': videoId == selectedVideoId }
      )}>
      <span className="block mb-6">
        {status === 'pending' && <PendingVideoIcon />}
        {status === 'processing' && <ProcessingVideoIcon />}
        {status === 'complete' && <CompleteVideoIcon />}
      </span>
      <p
        className="text-lg font-bold text-black mb-2"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, `myProfile.video`, orgPrefix, true, videoId - 1, 'label')
        }}
      />
      <p
        className="text-base font-normal text-grey-200 mb-10"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, `myProfile.video`, orgPrefix, true, videoId - 1, 'sub')
        }}
      />

      <div className="flex w-full justify-end">
        <EditVideoIcon />
      </div>
    </button>
  );
};
