import { saveAs } from 'file-saver';
import { findIndex } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProfileContext } from '../contexts/profileContext';
import masterOrganization from '../data/masterOrganization';
import {
  getProfileData,
  getUploadingFileData,
  postVideoLink,
  updateVideoRole,
  updateVideoStrength,
  uploadVideo
} from '../service';
import { getVideoUrl } from '../utils/utils';
import { Loader, RadioSelect } from './common';
import Button from './common/button';
import { VideoThumbnail } from './common/videoThumbnail';
import { ArchiveIcon, RefreshIcon } from './icons';

export default function Videos({ profile, groupDetails }) {
  const { t, i18n } = useTranslation();
  const fileInputRef = useRef(null);
  const newVideoRef = useRef(null);
  const { videosInformation } = groupDetails;

  const [adminProfle] = useContext(ProfileContext);

  const {
    masterOrganization: { _id: masterOrganizationId, customConfigurations: { colors } = {} } = {}
  } = adminProfle;

  const videoThumbnailUrl =
    masterOrganizationId === masterOrganization.WSF
      ? 'champion-video-thumbnail'
      : 'swiss-champion-video-thumbnail';

  const [videos, setVideos] = useState([]);
  const [roles, setRoles] = useState([]);
  const [strengths, setStrengths] = useState([]);
  const [selectedStrength, setSelectedStrength] = useState({});
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedVideo, setSelectedVideo] = useState({});
  const [newVideoFile, setNewVideoFile] = useState(null);
  const [newVideoUploadURL, setNewVideoUploadURL] = useState(null);
  const [isUploading, setIsUploading] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const fetchProfileVideos = async () => {
    try {
      const {
        data: { videos: videosData, roles: rolesData, strengths: strengthsData }
      } = await getProfileData(profile?._id);

      setVideos(videosData);

      const currentLanguage = i18n.language;

      // Helper function to map data to dropdown format
      const mapDataForDropdown = (data) =>
        data.map((item) => ({
          _id: item._id,
          label: item[`name_${currentLanguage}`],
          value: item._id
        }));

      const rolesDataForDropdown = mapDataForDropdown(rolesData);
      setRoles(rolesDataForDropdown);

      const strengthsDataForDropdown = mapDataForDropdown(strengthsData);
      setStrengths(strengthsDataForDropdown);

      // Helper function to set selected item from dropdown
      const setSelectedItem = (itemsData, id, setSelectedFunction) => {
        const currentItem = itemsData.find((item) => item._id === id);
        if (currentItem) {
          setSelectedFunction(currentItem);
        }
      };

      const currentSelectedRole = videosData.find((video) => video.videoID === '2')?.roleId;
      setSelectedItem(rolesDataForDropdown, currentSelectedRole, setSelectedRole);

      const currentSelectedStrength = videosData.find((video) => video.videoID === '1')?.strengthId;
      setSelectedItem(strengthsDataForDropdown, currentSelectedStrength, setSelectedStrength);
    } catch (error) {
      toast.error('Failed to get profile data');
      console.error('Failed to get profile data:', error);
    }
  };

  useEffect(() => {
    fetchProfileVideos();
  }, [profile]);

  const handleVideoItemSelect = (selectedVideoId) => {
    clearFileSelection();
    const itemIndex = findIndex(
      videos,
      ({ videoID: tVideoID }) => parseInt(tVideoID) === selectedVideoId
    );

    if (itemIndex >= 0) {
      setSelectedVideo(videos[itemIndex]);
      return;
    } else {
      // video doesn't exist
      setSelectedVideo({
        videoURL: `${profile?._id}/${selectedVideoId}`,
        videoID: selectedVideoId,
        isNewVideo: true
      });
    }
  };

  const handleStrengthSelect = async (selectedStrength, video) => {
    try {
      await updateVideoStrength({
        userId: profile?._id,
        strengthId: selectedStrength,
        videoId: video?._id
      });
      toast.success('Video strength updated sucessfully.');

      const updatedStrenghts = cloneDeep(strengths).map((strength) => ({
        ...strength,
        isSelected: strength._id === selectedStrength
      }));

      setSelectedStrength(updatedStrenghts.find((strength) => strength.isSelected));
      setStrengths(updatedStrenghts);

      setSelectedVideo({
        ...video,
        strengthId: selectedStrength
      });
    } catch (error) {
      toast.error('Failed to update strength.');
      console.error('Failed to update strength:', error);
    }
  };

  const handleRoleSelect = async (selectedRole, video) => {
    try {
      await updateVideoRole({
        userId: profile?._id,
        roleId: selectedRole,
        videoId: video?._id
      });
      toast.success('Video strength updated sucessfully.');

      const updatedRoles = cloneDeep(roles).map((role) => ({
        ...role,
        isSelected: role._id === selectedRole
      }));

      setSelectedStrength(updatedRoles.find((role) => role.isSelected));
      setRoles(updatedRoles);

      setSelectedVideo({
        ...video,
        roleId: selectedRole
      });
    } catch (error) {
      toast.error('Failed to update role.');
      console.error('Failed to update role:', error);
    }
  };

  const uploadNewVideo = async () => {
    const videoDuration = newVideoRef.current.duration;
    try {
      setIsUploading(true);
      const vidUrl = selectedVideo?.videoURL;
      const videoName =
        vidUrl && vidUrl !== 'undefined' && vidUrl !== 'null'
          ? vidUrl
          : profile?._id && selectedVideo?.videoID
          ? `${profile?._id}/${selectedVideo?.videoID}`
          : `video-${Date.now()}`; // Generate a random name if all checks fail
      var body = {
        type: newVideoFile.type,
        name: videoName,
        keepOriginal: true,
        isProfileVideo: true
      };
      const {
        data: { url, finalName }
      } = await getUploadingFileData(body);
      const data = await uploadVideo(url, newVideoFile, setUploadProgress, body.type);
      const uploadResponse = await postVideoLink({
        videoURL: finalName,
        videoID: selectedVideo.videoID,
        trimPoints: [0, videoDuration],
        userId: profile?._id
      });
      toast.success(uploadResponse.data.message);
      // setSelectedVideo({ ...selectedVideo, isNewVideo: false });
    } catch (e) {
      console.log('error', e);
      toast.error(e.message);
    } finally {
      setIsUploading(false);
      clearFileSelection();
    }
  };

  const handleFileSelection = (e) => {
    var file = e.target.files[0];
    if (!file) return;
    setNewVideoFile(file);
    setNewVideoUploadURL(URL.createObjectURL(file));
    e.target.value = null;
  };

  const clearFileSelection = () => {
    setNewVideoFile(null);
    setNewVideoUploadURL(null);
  };

  const isSomeVideoSelected = Object.keys(selectedVideo)?.length > 0;

  return (
    <>
      {isUploading && <Loader transparent="true" />}
      <div className="relative min-h-[265px]">
        <div className="absolute left-0 top-0 w-full flex flex-row overflow-x-auto gap-4 pr-12">
          {videosInformation.map(({ isActive }, index) => {
            if (!isActive) return null;
            return (
              <VideoThumbnail
                key={index}
                videoId={index + 1}
                videos={videos}
                onSelect={handleVideoItemSelect}
                selectedVideoId={selectedVideo?.videoID}
              />
            );
          })}
        </div>
      </div>
      {isSomeVideoSelected && (
        <div className="flex items-center justify-center gap-6">
          {selectedVideo?.videoURL && !selectedVideo?.isNewVideo && (
            <Button.ButtonFilled
              type="button"
              className="flex items-center gap-2"
              onClick={() => {
                const downloadVideoUrl = `${process.env.REACT_APP_AWS_URL}profile-videos/${selectedVideo?.videoURL}`;
                saveAs(downloadVideoUrl, 'video.mp4');
              }}>
              <ArchiveIcon color="#ffffff" /> {t('editProfileVideos.download')}
            </Button.ButtonFilled>
          )}

          <Button.ButtonOutlined type="button">
            <label htmlFor="FileInput" className="flex items-center gap-2">
              <RefreshIcon color={colors?.primary} /> {t('editProfileVideos.replace')}
              <div>
                <input
                  ref={fileInputRef}
                  id="FileInput"
                  className="hidden"
                  type="file"
                  accept="video/*"
                  onChange={handleFileSelection}
                />
              </div>
            </label>
          </Button.ButtonOutlined>
        </div>
      )}

      <>
        {/* Strength */}
        {Number(selectedVideo?.videoID) === 1 && !selectedVideo.isNewVideo && (
          <div className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between">
            <RadioSelect
              label={t('editProfileVideos.selectStrength')}
              className="my-1"
              name="strength"
              selected={selectedStrength}
              options={strengths}
              onChange={(option) => handleStrengthSelect(option, selectedVideo)}
            />
          </div>
        )}
        {/* Role */}
        {Number(selectedVideo?.videoID) === 2 && !selectedVideo.isNewVideo && (
          <div className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between">
            <RadioSelect
              label={t('editProfileVideos.selectRole')}
              className="my-1"
              name="role"
              selected={selectedRole}
              options={roles}
              onChange={(option) => handleRoleSelect(option, selectedVideo)}
            />
          </div>
        )}

        <div className="h-[400px]">
          {selectedVideo?.videoID && !newVideoUploadURL && (
            <video
              className="h-full rounded-md object-contain w-full mt-4"
              poster={`${process.env.REACT_APP_AWS_URL}${videoThumbnailUrl}/video-${selectedVideo.videoID}.png`}
              src={getVideoUrl(selectedVideo)}
              playsInline
              start="0"
              end="0"
              controls></video>
          )}
          {newVideoUploadURL && (
            <video
              ref={newVideoRef}
              src={newVideoUploadURL}
              className="h-full rounded-md object-contain w-full mt-4"
              playsInline
              start="0"
              end="0"
              controls
            />
          )}
        </div>
        {newVideoUploadURL && (
          <div className="w-full flex items-center justify-between">
            <Button.ButtonOutlined
              type="button"
              className="flex items-center gap-2 my-4"
              onClick={clearFileSelection}>
              {t('editProfileVideos.cancel')}
            </Button.ButtonOutlined>
            <Button.ButtonFilled
              type="button"
              className="flex items-center gap-2 my-4"
              onClick={uploadNewVideo}>
              {t('editProfileVideos.submit')}
            </Button.ButtonFilled>
          </div>
        )}
      </>
    </>
  );
}
