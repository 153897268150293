import clsx from 'clsx';

export const LoginIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.375 8.875H56.2083C57.7775 8.875 59.2825 9.49836 60.392 10.608C61.5016 11.7175 62.125 13.2225 62.125 14.7917V56.2083C62.125 57.7775 61.5016 59.2825 60.392 60.392C59.2825 61.5016 57.7775 62.125 56.2083 62.125H44.375"
        stroke="#191919"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.582 50.2918L44.3737 35.5002L29.582 20.7085"
        stroke="#191919"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.375 35.5H8.875"
        stroke="#191919"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BluePlusIcon = ({ isSelected = false }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="#3360C5" />
      <path
        d="M9.04245 13.2851L9.04245 4.84248M4.95694 8.88535L13.2424 8.88535"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export const GrayPlusIcon = ({ isSelected = false }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="rgba(51, 96, 197, 0.12)" />
      <path
        d="M9.04245 13.2851L9.04245 4.84248M4.95694 8.88535L13.2424 8.88535"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export const ToggleArrow = ({ isSelected = false }) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isSelected ? 'text-white' : 'text-black'}`}>
      <path
        d="M6 7.5L0.803847 0.75L11.1962 0.750001L6 7.5Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
};

export const MeatballsMenu = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="1" stroke="#33363F" strokeWidth="2" strokeLinecap="round" />
      <circle cx="6" cy="12" r="1" stroke="#33363F" strokeWidth="2" strokeLinecap="round" />
      <circle cx="18" cy="12" r="1" stroke="#33363F" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export const DefaultUserIcon = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="55"
      height="56"
      viewBox="0 0 55 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="55" height="55" rx="27.5" fill="#EFEFEF" />
      <path
        d="M35.2274 36.4471C34.7716 35.1713 33.7672 34.0439 32.3701 33.2399C30.9729 32.4358 29.2611 32 27.5 32C25.7389 32 24.0271 32.4358 22.6299 33.2399C21.2328 34.0439 20.2284 35.1713 19.7726 36.4471"
        stroke="#606060"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="27.5" cy="24" r="4" stroke="#606060" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export const NotificationIcon = ({ className, onClick, hasNotification = false }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5075 2.66406H11.4873C14.9856 2.66406 17.8215 5.49997 17.8215 8.99824C17.8215 9.35204 17.943 9.6951 18.1658 9.96996L18.9427 9.34031L18.1658 9.96997L18.4812 10.3591C19.7641 11.9419 19.9262 14.156 18.8875 15.9088L19.7478 16.4186L18.8875 15.9088L18.8244 16.0153L18.8238 16.0162L18.8029 16.0514C18.8026 16.0519 18.8023 16.0524 18.802 16.0529C18.083 17.2533 16.7889 17.9907 15.3896 17.9974C15.389 17.9974 15.3884 17.9974 15.3878 17.9974L15.3469 17.9974H15.3459H6.6489H6.64789L6.60698 17.9974C6.60639 17.9974 6.6058 17.9974 6.60522 17.9974C5.20587 17.9907 3.91171 17.2533 3.19266 16.0528C3.19239 16.0523 3.19212 16.0518 3.19185 16.0514L3.17095 16.0162L3.17043 16.0153L3.10729 15.9088C2.06862 14.156 2.23071 11.9419 3.51358 10.3591L3.82898 9.96997C4.05175 9.6951 4.17332 9.35204 4.17332 8.99824C4.17332 5.49997 7.00923 2.66406 10.5075 2.66406Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.75 21.1641V21.1641C9.375 23.3307 12.625 23.3307 14.25 21.1641V21.1641"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      {hasNotification && (
        <circle cx="19" cy="6.5" r="5" fill="#E64A4A" stroke="white" strokeWidth="2" />
      )}
    </svg>
  );
};

export const PlusButtonIcon = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="19" height="19" rx="5" fill="#515151" />
      <path
        d="M9.5 5L9.5 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.5L5 9.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SortIcon = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1.5L5.46967 0.96967L6 0.43934L6.53033 0.96967L6 1.5ZM6.75 12.75C6.75 13.1642 6.41421 13.5 6 13.5C5.58579 13.5 5.25 13.1642 5.25 12.75L6.75 12.75ZM1.71967 4.71967L5.46967 0.96967L6.53033 2.03033L2.78033 5.78033L1.71967 4.71967ZM6.53033 0.96967L10.2803 4.71967L9.21967 5.78033L5.46967 2.03033L6.53033 0.96967ZM6.75 1.5L6.75 12.75L5.25 12.75L5.25 1.5L6.75 1.5Z"
        fill="#515151"
      />
      <path
        d="M12 16.5L11.4697 17.0303L12 17.5607L12.5303 17.0303L12 16.5ZM12.75 5.25C12.75 4.83579 12.4142 4.5 12 4.5C11.5858 4.5 11.25 4.83579 11.25 5.25L12.75 5.25ZM7.71967 13.2803L11.4697 17.0303L12.5303 15.9697L8.78033 12.2197L7.71967 13.2803ZM12.5303 17.0303L16.2803 13.2803L15.2197 12.2197L11.4697 15.9697L12.5303 17.0303ZM12.75 16.5L12.75 5.25L11.25 5.25L11.25 16.5L12.75 16.5Z"
        fill="#515151"
      />
    </svg>
  );
};

export const PlusIcon = ({ className, color = '#737373', onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6L12 18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M18 12L6 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CrossIcon = ({ className, color = '#515151', onClick = () => {} }) => {
  return (
    <svg
      className={clsx('cursor-pointer', className)}
      onClick={onClick}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.273438" y="0.273438" width="22.7279" height="22.7279" rx="5" fill={color} />
      <path
        d="M14.8203 8.45312L8.45635 14.8171"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M14.8203 14.8203L8.45635 8.45635"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RadioIcon = ({
  className,
  isChecked = false,
  color = '#7C7D82',
  checkColor = '#ffffff'
}) => {
  return (
    <svg
      className={className}
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12" cy="11" rx="11.5" ry="11" fill={isChecked ? color : '#E5E8ED'} />
      {isChecked && (
        <path
          d="M7.07031 11.1676L9.55387 13.5015C9.93877 13.8633 10.5386 13.8633 10.9235 13.5015L16.9275 7.85938"
          stroke={checkColor}
          strokeLinecap="round"
        />
      )}
    </svg>
  );
};

export const EmailIcon = ({ className, color = '#737373' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="5.32812" width="12" height="9" rx="2" stroke={color} strokeWidth="1.5" />
      <path
        d="M3.5 7.57812L8.60557 10.1309C9.16863 10.4124 9.83137 10.4124 10.3944 10.1309L15.5 7.57812"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ExportIcon = ({ className, color = '#737373' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 4.57812L8.96967 4.04779L9.5 3.51746L10.0303 4.04779L9.5 4.57812ZM10.25 11.3281C10.25 11.7423 9.91421 12.0781 9.5 12.0781C9.08579 12.0781 8.75 11.7423 8.75 11.3281L10.25 11.3281ZM5.21967 7.79779L8.96967 4.04779L10.0303 5.10845L6.28033 8.85846L5.21967 7.79779ZM10.0303 4.04779L13.7803 7.79779L12.7197 8.85845L8.96967 5.10845L10.0303 4.04779ZM10.25 4.57812L10.25 11.3281L8.75 11.3281L8.75 4.57812L10.25 4.57812Z"
        fill={color}
      />
      <path
        d="M4.25 12.8281L4.25 13.5781C4.25 14.4066 4.92157 15.0781 5.75 15.0781L13.25 15.0781C14.0784 15.0781 14.75 14.4066 14.75 13.5781V12.8281"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const UserAddIcon = ({ className, color = '#737373' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.5" cy="6.82812" r="3" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4806 11.8819C10.156 11.8462 9.82799 11.8281 9.49895 11.8281C8.01974 11.8281 6.561 12.193 5.34759 12.8913C4.13476 13.5893 3.19808 14.6055 2.76169 15.827C2.57588 16.3471 2.84687 16.9193 3.36696 17.1051C3.88705 17.291 4.45929 17.02 4.6451 16.4999C4.89242 15.8076 5.46228 15.1329 6.34518 14.6248C6.97978 14.2595 7.73168 14.0076 8.53636 13.8949C8.71193 12.8899 9.48765 12.0913 10.4806 11.8819Z"
        fill={color}
      />
      <path d="M14 11.3281L14 17.3281" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 14.3281L11 14.3281" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export const ArchiveIcon = ({ className, color = '#737373' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 9.82812H14.3204C13.7964 9.82812 13.5344 9.82812 13.3133 9.94645C13.0922 10.0648 12.9469 10.2828 12.6563 10.7187L12.3437 11.1875C12.0531 11.6235 11.9078 11.8415 11.6867 11.9598C11.4656 12.0781 11.2036 12.0781 10.6796 12.0781H8.32037C7.7964 12.0781 7.53442 12.0781 7.31333 11.9598C7.09223 11.8415 6.94691 11.6235 6.65627 11.1875L6.34373 10.7187C6.05309 10.2828 5.90777 10.0648 5.68667 9.94645C5.46558 9.82812 5.2036 9.82812 4.67963 9.82812H2.75"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M4.25 7.57812L3.04289 8.78523C2.85536 8.97277 2.75 9.22712 2.75 9.49234V13.0781C2.75 14.1827 3.64543 15.0781 4.75 15.0781H14.25C15.3546 15.0781 16.25 14.1827 16.25 13.0781V9.49234C16.25 9.22712 16.1446 8.97277 15.9571 8.78523L14.75 7.57812"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.875 6.07812L9.5 8.32813M9.5 8.32813L12.125 6.07813M9.5 8.32813L9.5 3.07813"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const CancelIcon = ({ className, color = '#737373' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="13"
      viewBox="0 0 19 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9922 12.0781L4.50257 1.58851"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.9922 1.57031L4.50257 12.0599"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const RightArrowFilledIcon = ({ className, color = '#000000' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 15.5C12.9779 15.5 16 12.4779 16 8.75C16 5.02208 12.9779 2 9.25 2C5.52208 2 2.5 5.02208 2.5 8.75C2.5 12.4779 5.52208 15.5 9.25 15.5ZM10.2303 5.46967L13.0303 8.26967L13.5607 8.8L13.0303 9.33033L10.2303 12.1303L9.16967 11.0697L10.6893 9.55H5.5C5.08579 9.55 4.75 9.21421 4.75 8.8C4.75 8.38579 5.08579 8.05 5.5 8.05H10.6893L9.16967 6.53033L10.2303 5.46967Z"
        fill={color}
      />
    </svg>
  );
};

export const HourGlassIcon = ({ className, color = '#000000' }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.44444 9.66406L14.0143 12.7746C14.5614 13.1471 14.8889 13.7661 14.8889 14.428V16.1641C14.8889 16.4402 14.665 16.6641 14.3889 16.6641H4.5C4.22386 16.6641 4 16.4402 4 16.1641V14.428C4 13.7661 4.32746 13.1471 4.87462 12.7746L9.44444 9.66406ZM9.44444 9.66406L14.0143 6.55351C14.5614 6.18107 14.8889 5.56206 14.8889 4.90017V3.16406C14.8889 2.88792 14.665 2.66406 14.3889 2.66406H4.5C4.22386 2.66406 4 2.88792 4 3.16406V4.90017C4 5.56206 4.32746 6.18107 4.87462 6.55351L9.44444 9.66406Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.776 16.0951V16.5158C11.776 16.5986 11.7089 16.6658 11.626 16.6658H7.25937C7.17653 16.6658 7.10938 16.5986 7.10938 16.5158V16.0951C7.10938 15.9625 7.16205 15.8353 7.25582 15.7416L8.87702 14.1204C9.18944 13.808 9.69597 13.808 10.0084 14.1204L11.6296 15.7416C11.7234 15.8353 11.776 15.9625 11.776 16.0951Z"
        fill={color}
      />
      <path d="M9.45139 8.88281L13.3403 6.54948H5.5625L9.45139 8.88281Z" fill={color} />
      <path
        d="M9 14.6667V10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseButtonIcon = ({ className, color = '#000000', onClick }) => {
  return (
    <svg
      className={clsx('cursor-pointer', className)}
      onClick={onClick}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5013 2.83073C7.11847 2.83073 5.84375 2.94477 4.82059 3.0799C3.89977 3.20152 3.20542 3.89587 3.08381 4.81669C2.94868 5.83984 2.83464 7.11456 2.83464 8.4974C2.83464 9.88023 2.94868 11.1549 3.08381 12.1781C3.20542 13.0989 3.89977 13.7933 4.82059 13.9149C5.84375 14.05 7.11847 14.1641 8.5013 14.1641C9.88413 14.1641 11.1589 14.05 12.182 13.9149C13.1028 13.7933 13.7972 13.0989 13.9188 12.1781C14.0539 11.1549 14.168 9.88023 14.168 8.4974C14.168 7.11456 14.0539 5.83984 13.9188 4.81669C13.7972 3.89587 13.1028 3.20152 12.182 3.0799C11.1589 2.94477 9.88413 2.83073 8.5013 2.83073ZM4.63511 1.67543C3.07904 1.88094 1.88485 3.07513 1.67934 4.6312C1.53806 5.70095 1.41797 7.03913 1.41797 8.4974C1.41797 9.95566 1.53806 11.2938 1.67934 12.3636C1.88485 13.9197 3.07904 15.1138 4.6351 15.3194C5.70486 15.4606 7.04304 15.5807 8.5013 15.5807C9.95957 15.5807 11.2977 15.4606 12.3675 15.3194C13.9236 15.1138 15.1178 13.9197 15.3233 12.3636C15.4645 11.2938 15.5846 9.95566 15.5846 8.4974C15.5846 7.03913 15.4645 5.70095 15.3233 4.6312C15.1178 3.07514 13.9236 1.88094 12.3675 1.67543C11.2977 1.53415 9.95957 1.41406 8.5013 1.41406C7.04304 1.41406 5.70486 1.53415 4.63511 1.67543Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.87543 5.87153C6.15206 5.59491 6.60055 5.59491 6.87717 5.87153L8.5013 7.49566L10.1254 5.87153C10.4021 5.59491 10.8505 5.59491 11.1272 5.87153C11.4038 6.14815 11.4038 6.59664 11.1272 6.87326L9.50304 8.4974L11.1272 10.1215C11.4038 10.3981 11.4038 10.8466 11.1272 11.1233C10.8505 11.3999 10.4021 11.3999 10.1254 11.1233L8.5013 9.49913L6.87717 11.1233C6.60055 11.3999 6.15206 11.3999 5.87543 11.1233C5.59881 10.8466 5.59881 10.3981 5.87543 10.1215L7.49957 8.4974L5.87543 6.87326C5.59881 6.59664 5.59881 6.14815 5.87543 5.87153Z"
        fill={color}
      />
    </svg>
  );
};

export const FunnelIcon = ({ className, color = '#000000', onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.96875 2.46875C1.96875 2.2947 2.03789 2.12778 2.16096 2.00471C2.28403 1.88164 2.45095 1.8125 2.625 1.8125H18.375C18.549 1.8125 18.716 1.88164 18.839 2.00471C18.9621 2.12778 19.0312 2.2947 19.0312 2.46875V5.09375C19.0312 5.25559 18.9714 5.41172 18.8632 5.53213L13.125 11.9083V18.2188C13.1249 18.3564 13.0815 18.4906 13.0009 18.6022C12.9204 18.7139 12.8067 18.7974 12.6761 18.8409L8.73863 20.1534C8.64002 20.1862 8.53503 20.1952 8.43229 20.1795C8.32955 20.1638 8.232 20.124 8.14767 20.0632C8.06334 20.0025 7.99465 19.9226 7.94725 19.8301C7.89984 19.7376 7.87508 19.6352 7.875 19.5312V11.9083L2.13675 5.53213C2.02861 5.41172 1.96878 5.25559 1.96875 5.09375V2.46875ZM3.28125 3.125V4.84175L9.0195 11.2179C9.12764 11.3383 9.18747 11.4944 9.1875 11.6562V18.6204L11.8125 17.7463V11.6562C11.8125 11.4944 11.8724 11.3383 11.9805 11.2179L17.7188 4.84175V3.125H3.28125Z"
        fill={color}
      />
    </svg>
  );
};

export const VisibilityIcon = ({ className, color = '#000000', onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14C6 15.1046 5.10457 16 4 16C2.89543 16 2 15.1046 2 14L2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4L6 14Z"
        fill={color}
      />
      <path
        d="M14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10L10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4L14 10Z"
        fill={color}
      />
      <path
        d="M22 20C22 21.1046 21.1046 22 20 22C18.8954 22 18 21.1046 18 20L18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4L22 20Z"
        fill={color}
      />
    </svg>
  );
};

export const BackspaceIcon = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3372_48362)">
        <path
          d="M12.9342 6.98186C12.9953 7.04282 13.0438 7.11524 13.0769 7.19497C13.11 7.27469 13.127 7.36017 13.127 7.44649C13.127 7.5328 13.11 7.61828 13.0769 7.698C13.0438 7.77773 12.9953 7.85015 12.9342 7.91111L10.1163 10.7277L12.9342 13.5444C13.0574 13.6676 13.1267 13.8347 13.1267 14.009C13.1267 14.1833 13.0574 14.3504 12.9342 14.4736C12.811 14.5968 12.6438 14.6661 12.4696 14.6661C12.2953 14.6661 12.1282 14.5968 12.005 14.4736L9.18833 11.6557L6.3717 14.4736C6.24848 14.5968 6.08135 14.6661 5.90708 14.6661C5.73281 14.6661 5.56568 14.5968 5.44245 14.4736C5.31923 14.3504 5.25 14.1833 5.25 14.009C5.25 13.8347 5.31923 13.6676 5.44245 13.5444L8.26039 10.7277L5.44245 7.91111C5.38144 7.85009 5.33304 7.77766 5.30002 7.69794C5.267 7.61822 5.25 7.53277 5.25 7.44649C5.25 7.3602 5.267 7.27475 5.30002 7.19503C5.33304 7.11531 5.38144 7.04288 5.44245 6.98186C5.50347 6.92084 5.5759 6.87244 5.65563 6.83942C5.73535 6.8064 5.82079 6.78941 5.90708 6.78941C5.99337 6.78941 6.07881 6.8064 6.15853 6.83942C6.23825 6.87244 6.31069 6.92084 6.3717 6.98186L9.18833 9.7998L12.005 6.98186C12.0659 6.92075 12.1383 6.87226 12.2181 6.83917C12.2978 6.80609 12.3833 6.78906 12.4696 6.78906C12.5559 6.78906 12.6414 6.80609 12.7211 6.83917C12.8008 6.87226 12.8732 6.92075 12.9342 6.98186Z"
          fill="currentColor"
        />
        <path
          d="M2.625 1.53906C1.92881 1.53906 1.26113 1.81562 0.768845 2.30791C0.276562 2.80019 0 3.46787 0 4.16406L0 17.2891C0 17.9853 0.276562 18.6529 0.768845 19.1452C1.26113 19.6375 1.92881 19.9141 2.625 19.9141H11.9175C12.2962 19.9142 12.6705 19.8324 13.0147 19.6743C13.3588 19.5162 13.6646 19.2854 13.9112 18.9979L20.2676 11.581C20.4717 11.3431 20.5838 11.04 20.5838 10.7266C20.5838 10.4131 20.4717 10.11 20.2676 9.87212L13.9125 2.45781C13.666 2.16968 13.3601 1.93835 13.0157 1.77975C12.6713 1.62115 12.2967 1.53904 11.9175 1.53906H2.625ZM11.9175 2.85156C12.1071 2.85155 12.2944 2.89261 12.4666 2.97191C12.6388 3.05121 12.7918 3.16687 12.915 3.31094L19.2701 10.7266L12.9124 18.1422C12.7893 18.2861 12.6365 18.4016 12.4646 18.4809C12.2926 18.5602 12.1055 18.6014 11.9162 18.6016H2.625C2.2769 18.6016 1.94306 18.4633 1.69692 18.2171C1.45078 17.971 1.3125 17.6372 1.3125 17.2891V4.16406C1.3125 3.81597 1.45078 3.48213 1.69692 3.23598C1.94306 2.98984 2.2769 2.85156 2.625 2.85156H11.9175Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3372_48362">
          <rect width="21" height="21" fill="white" transform="translate(0 0.226562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const EditIcon = ({ className, color = '#000000', onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.375 17.4167C2.375 16.9794 2.72944 16.625 3.16667 16.625H15.8333C16.2706 16.625 16.625 16.9794 16.625 17.4167V17.4167C16.625 17.8539 16.2706 18.2083 15.8333 18.2083H3.16667C2.72944 18.2083 2.375 17.8539 2.375 17.4167V17.4167Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2265 2.47146C12.9173 2.16229 12.416 2.16229 12.1069 2.47146L11.4113 3.16705L14.25 6.0058L14.9456 5.3102C15.2548 5.00104 15.2548 4.49978 14.9456 4.19062L13.2265 2.47146ZM5.47378 9.10455L10.2917 4.28664L13.1304 7.12539L8.31253 11.9433L5.47378 9.10455ZM4.35419 10.2241L3.95833 10.62V13.4587H6.79708L7.19294 13.0629L4.35419 10.2241ZM10.9873 1.35187C11.9148 0.424376 13.4186 0.424376 14.346 1.35187L16.0652 3.07103C16.9927 3.99853 16.9927 5.5023 16.0652 6.42979L7.91667 14.5783C7.61973 14.8753 7.21701 15.0421 6.79708 15.0421H3.95833C3.08388 15.0421 2.375 14.3332 2.375 13.4587V10.62C2.375 10.2001 2.54182 9.79734 2.83875 9.50041L10.9873 1.35187Z"
        fill={color}
      />
    </svg>
  );
};

export const RightArrowIcon = ({ className, color = '#000000', onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 9.5H2.25"
        stroke="#828282"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2722 9.33725L11.5662 5.97589C11.4339 5.88134 11.25 5.97596 11.25 6.13864V12.8614C11.25 13.024 11.4339 13.1187 11.5662 13.0241L16.2722 9.66275C16.3838 9.58298 16.3838 9.41702 16.2722 9.33725Z"
        fill="#828282"
      />
    </svg>
  );
};

export const NextButtonIcon = ({ className, onClick }) => {
  return (
    <svg
      className={clsx(className)}
      onClick={onClick}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="19"
        y="20.5"
        width="18"
        height="19"
        rx="4"
        transform="rotate(-180 19 20.5)"
        className="stroke-gray-900 group-hover:fill-gray-900"
        strokeWidth="2"
      />
      <g clipPath="url(#clip0_1927_53789)">
        <path
          d="M7.5 6.5L12 11L7.5 15.5"
          className="stroke-gray-900 group-hover:stroke-white"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1927_53789">
          <rect width="10" height="11" fill="white" transform="translate(15 16.5) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MessageIcon = ({ className, color = '#ffffff' }) => {
  return (
    <svg
      className={className}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="5.75" width="14" height="10.5" rx="2" stroke={color} strokeWidth="2" />
      <path
        d="M3.5 8.375L9.60557 11.4278C10.1686 11.7093 10.8314 11.7093 11.3944 11.4278L17.5 8.375"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export const BackButtonIcon = ({ className, color = '#000000', onClick }) => {
  return (
    <svg
      className={clsx('cursor-pointer', className)}
      onClick={onClick}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="21" rx="5" fill={color} />
      <path d="M12.5 15L8 10.5L12.5 6" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const SimpleCrossIcon = ({ className, color = '#828282', onClick }) => {
  return (
    <svg
      className={className}
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4922 11.25L4.00257 0.760382"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.4922 0.742188L4.00257 11.2318"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const RefreshIcon = ({ color = '#000000', className }) => {
  return (
    <svg
      className={clsx(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14 15L10 19L14 23" stroke={color} strokeWidth="2" />
      <path
        d="M5.93782 15.5C5.16735 14.1655 4.85875 12.6141 5.05989 11.0863C5.26102 9.55856 5.96064 8.13986 7.05025 7.05025C8.13986 5.96064 9.55856 5.26102 11.0863 5.05989C12.6141 4.85875 14.1655 5.16735 15.5 5.93782C16.8345 6.70829 17.8775 7.89757 18.4672 9.32122C19.0568 10.7449 19.1603 12.3233 18.7615 13.8117C18.3627 15.3002 17.4838 16.6154 16.2613 17.5535C15.0388 18.4915 13.5409 19 12 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const PendingVideoIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14" stroke="black" strokeWidth="2" strokeDasharray="4 4" />
    </svg>
  );
};

export const ProcessingVideoIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 0.470703C12.3096 0.470703 11.75 1.02376 11.75 1.706V5.41188C11.75 6.09411 12.3096 6.64717 13 6.64717C13.6904 6.64717 14.25 6.09411 14.25 5.41188V1.706C14.25 1.02376 13.6904 0.470703 13 0.470703Z"
        fill="black"
      />
      <path
        d="M13 19.0001C12.3096 19.0001 11.75 19.5532 11.75 20.2354V23.9413C11.75 24.6235 12.3096 25.1766 13 25.1766C13.6904 25.1766 14.25 24.6235 14.25 23.9413V20.2354C14.25 19.5532 13.6904 19.0001 13 19.0001Z"
        fill="black"
      />
      <path
        d="M24.25 11.5884C24.9404 11.5884 25.5 12.1414 25.5 12.8236C25.5 13.5059 24.9404 14.0589 24.25 14.0589H20.5C19.8096 14.0589 19.25 13.5059 19.25 12.8236C19.25 12.1414 19.8096 11.5884 20.5 11.5884H24.25Z"
        fill="black"
      />
      <path
        d="M6.75 12.8236C6.75 12.1414 6.19036 11.5884 5.5 11.5884H1.75C1.05964 11.5884 0.5 12.1414 0.5 12.8236C0.5 13.5059 1.05964 14.0589 1.75 14.0589H5.5C6.19036 14.0589 6.75 13.5059 6.75 12.8236Z"
        fill="black"
      />
      <path
        d="M20.0711 4.08892C20.5593 3.60651 21.3507 3.60651 21.8389 4.08892C22.3271 4.57133 22.3271 5.35347 21.8389 5.83589L19.1873 8.45634C18.6991 8.93875 17.9076 8.93875 17.4195 8.45634C16.9313 7.97393 16.9313 7.19178 17.4195 6.70937L20.0711 4.08892Z"
        fill="black"
      />
      <path
        d="M8.58054 17.191C8.09238 16.7086 7.30093 16.7086 6.81277 17.191L4.16112 19.8115C3.67297 20.2939 3.67297 21.076 4.16112 21.5584C4.64928 22.0408 5.44073 22.0408 5.92889 21.5584L8.58054 18.938C9.0687 18.4556 9.0687 17.6734 8.58054 17.191Z"
        fill="black"
      />
      <path
        d="M21.8389 19.8116C22.327 20.294 22.327 21.0761 21.8389 21.5586C21.3507 22.041 20.5593 22.041 20.0711 21.5586L17.4194 18.9381C16.9313 18.4557 16.9313 17.6735 17.4194 17.1911C17.9076 16.7087 18.6991 16.7087 19.1872 17.1911L21.8389 19.8116Z"
        fill="black"
      />
      <path
        d="M8.58066 8.45618C9.06881 7.97376 9.06881 7.19162 8.58066 6.70921L5.92901 4.08875C5.44085 3.60634 4.64939 3.60634 4.16124 4.08875C3.67308 4.57116 3.67308 5.35331 4.16124 5.83572L6.81289 8.45618C7.30104 8.93859 8.0925 8.93859 8.58066 8.45618Z"
        fill="black"
      />
    </svg>
  );
};

export const CompleteVideoIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="black" />
      <path d="M8 17L12 20L21 9" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const EditVideoIcon = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15.5" r="15" fill="#E2E2E2" />
      <path d="M12 9.5L18 15.5L12 21.5" stroke="black" strokeWidth="2" />
    </svg>
  );
};

export const ViewIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="2.25" stroke="#606060" strokeWidth="1.5" />
      <path
        d="M14.9236 7.94266C15.3177 8.3999 15.5147 8.62851 15.5147 9C15.5147 9.37149 15.3177 9.6001 14.9236 10.0573C13.777 11.3875 11.5542 13.5 9 13.5C6.4458 13.5 4.22295 11.3875 3.07643 10.0573C2.68233 9.6001 2.48528 9.37149 2.48528 9C2.48528 8.62851 2.68233 8.3999 3.07643 7.94266C4.22295 6.61246 6.4458 4.5 9 4.5C11.5542 4.5 13.777 6.61246 14.9236 7.94266Z"
        stroke="#606060"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const RequiredIcon = ({ className }) => {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 576 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
    </svg>
  );
};
